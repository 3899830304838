<template>
    <v-dialog
        max-width="600px !important"
        overlay=false
        persistent
        scrollable
        transition="dialog-bottom-transition"
        v-model="open"
    >
        <v-card width="600px">
            <v-card-title class="success white--text" style="width: 600px">{{ $t('message.shippingDocuments') + ": " + mainDocument }}</v-card-title>
            <v-card-text class="pa-3 overflow-y-auto">
                <v-simple-table class="appic-table-light specification-tables" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="width-1-pct"></th>
                                <th>{{ $t('message.source') }}</th>
                                <th>{{ $t('message.name') }}</th>
                                <th>{{ $t('message.uploaded') }}</th>
                                <th>{{ $t('message.actions') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="document in shippingDocuments">
                                <td>
                                    <v-checkbox
                                        :value="document.Document.location"
                                        class="mt-0"
                                        dense
                                        hide-details
                                        v-model="selectedDocuments"
                                    ></v-checkbox>
                                </td>
                                <td>{{ document.Document.source }}</td>
                                <td>{{ document.Document.name }}</td>
                                <td>{{ formatDate(document.Document.uploaded) }}</td>
                                <td>
                                    <v-btn icon @click="viewFile(document.Document.location)">
                                        <v-icon class="pl-2" style="color: darkgray !important;" small>fas fa-cloud-download-alt</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-btn
                    :loading="loading.merge"
                    :disabled="selectedDocuments.length === 0"
                    class="mt-2"
                    color="default"
                    small
                    @click="downloadMergedPdf()"
                    v-if="shippingDocuments.length > 0 && $can('merge','ShippingDocuments') === false"
                >
                    <v-icon class="pr-2" style="color: darkgray !important;" small>fas fa-cloud-download-alt</v-icon>
                    {{ $t('message.downloadShippingDocumentsMergedPdf') }}
                </v-btn>
                <span v-if="loading.invoice" class="green--text darken-1">{{ $t('message.pleaseWait') }}</span>
                <v-btn
                    :loading="loading.mergeV2"
                    :disabled="selectedDocuments.length == 0"
                    class="mt-2"
                    small
                    @click="downloadMergedPdfV2()"
                    v-if="shippingDocuments.length > 0 && $can('merge','ShippingDocuments') && loading.invoice === false"
                >
                    <v-icon class="pr-2" style="color: darkgray !important;" small>fas fa-cloud-download-alt</v-icon>
                    {{ $t('message.downloadShippingDocumentsMergedPdf') }}
                </v-btn>
                <div class="text-center" v-if="shippingDocuments.length === 0">{{ $t('message.noShippingDocumentsAvailable') }}</div>
                <div class="font-weight-bold pt-4" v-if="appOnlyDocuments.length > 0">{{ $t('message.appOnlyDocuments') }}</div>
                <v-simple-table class="appic-table-light specification-tables pt-2" dense v-if="appOnlyDocuments.length > 0">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>{{ $t('message.source') }}</th>
                                <th>{{ $t('message.name') }}</th>
                                <th>{{ $t('message.uploaded') }}</th>
                                <th>{{ $t('message.actions') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="document in appOnlyDocuments">
                                <td>{{ document.Document.source }}</td>
                                <td>{{ document.Document.name }}</td>
                                <td>{{ formatDate(document.Document.uploaded) }}</td>
                                <td>
                                    <v-btn icon @click="viewFile(document.Document.location)">
                                        <v-icon class="pl-2" style="color: darkgray !important;" small>fas fa-eye</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-3"
                    color="default"
                    small
                    @click="open = false"
                >
                    {{ $t('message.close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <PrintInvoiceBlob :invoice-id="invoiceId" :is-tax-invoice="isTaxInvoice" @pdf-uploading="invoiceUploading" @pdf-uploaded="invoiceUploaded" @pdf-not-uploaded="invoiceNotUploaded()" v-if="hasInvoice && $can('merge','ShippingDocuments')" :key="invoiceId"></PrintInvoiceBlob>
    </v-dialog>
</template>

<script>

import {formatDate} from "Helpers/helpers";
import Api2Pdf from "api2pdf";
import {mapGetters} from "vuex";
import {api} from "Api";
import {mapFields} from "vuex-map-fields";

const PrintInvoiceBlob = () => import('Views/v1/invoices/PrintInvoiceBlob')

export default {
    name: "ShippingDocumentsList",
    components: {PrintInvoiceBlob},
    props: {
        mainDocument: {
            type: String,
        },
        openDialog: {
            type: Boolean,
        },
        shippingDocuments: {
            type: Array,
            default: () => {
                return []
            }
        },
        appOnlyDocuments: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data(){
        return {
            hasInvoice: false,
            invoiceId: false,
            isTaxInvoice: false,
            loading: {
                invoice: false,
                merge: false,
                mergeV2: false,
                compress: false,
            },
            open: false,
            pdfComponentKey: 0,
            selectedDocuments: []
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('user',{
            api2Pdf : 'api2Pdf'
        }),
    },
    methods: {
        downloadMergedPdf () {
            if(this.api2Pdf.key != '') {
                if (this.selectedDocuments.length > 1) {
                    this.loading.merge = true
                    let self = this
                    let a2pClient = new Api2Pdf(this.api2Pdf.key)
                    a2pClient.merge(this.selectedDocuments, true, this.mainDocument + " MERGED DOCUMENTS" + '.pdf').then(function (result) {
                        if (result.success == true) {
                            let tab = window.open(result.pdf, '_blank')
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        }
                    });
                    self.loading.merge = false
                    this.open = false
                    this.$toast.success(this.$t('message.successes.pdfMergedPleaseWaitForDownload'),
                        {
                            timeout: 3000,
                            color: 'info',
                            classes: ['icon-float-left'],
                            icon: 'check_circle_outline',
                            x: 'centered',
                            y: 'top'
                        }
                    )
                } else if (this.selectedDocuments.length == 1) {
                    alert(this.$t('message.selectMoreThanOneFile'))
                } else {
                    alert(this.$t('message.noFilesSelected'))
                }
            } else {
                alert(this.$t('message.api2PdfKeyNotAvailable'))
            }
        },
        async downloadMergedPdfV2 (){
            if (this.selectedDocuments.length > 1) {
                this.loading.mergeV2 = true
                try {
                    let self = this
                    setTimeout(function(){
                        self.loading.mergeV2 = false
                    }, 1000)

                    this.$toast(this.$t('message.successes.mergedFileReadyMoment'),{
                        classes: ['text--white'],
                        color: 'info',
                        timeout: 6000,
                        x: 'center',
                        y: 'top'
                    })

                    let mergedPdf = await api.post('/documents/merge-pdf',{
                            files: this.selectedDocuments
                        }, {
                            responseType: 'blob'
                        })

                    const url = window.URL.createObjectURL(new Blob([mergedPdf.data], { type: 'application/pdf' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank'
                    link.setAttribute('download', this.mainDocument + " MERGED DOCUMENTS" + '.pdf');
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);

                } catch (error) {
                    this.loading.merge = false
                    this.$toast.error(this.$t('message.errors.couldNotMergeFiles') + ": " + error,{
                        timeout: 7000,
                        x: 'center',
                        y: 'top'
                    })
                }

            } else if (this.selectedDocuments.length == 1) {
                alert(this.$t('message.selectMoreThanOneFile'))
            } else {
                alert(this.$t('message.noFilesSelected'))
            }
        },
        formatDate,
        invoiceUploading() {
            // this.loading.invoice = true
        },
        invoiceUploaded(url) {
            this.loading.invoice = false
            this.shippingDocuments.forEach((document, idx) => {
                if(document.Document.name == 'Invoice' || document.Document.name == 'Tax Invoice'){
                    this.shippingDocuments[idx].Document.location = url
                }
            })
            this.selectedDocuments = []
            this.shippingDocuments.forEach(document => {
                this.selectedDocuments.push(document.Document.location)
            })
            this.loading.merge = false
        },
        invoiceNotUploaded() {
            this.loading.merge = false
        },
        viewFile (file) {
            let tab = window.open(file, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        open (value) {
            if(value == false){
                this.selectedDocuments = []
                this.generatePdf = false
                this.hasInvoice = false
                this.invoiceId = null
                this.isTaxInvoice = false
                this.loading.invoice = false
                this.loading.merge = false
                this.$emit('dialog-closed')
            }
        },
        openDialog (value) {
            if(value){
                this.open = value
                this.selectedDocuments = []
            }
            this.shippingDocuments.forEach(document => {
                this.selectedDocuments.push(document.Document.location)
                if((document.Document.name == 'Invoice' || document.Document.name == 'Tax Invoice') && document.Document?.invoice_id && document.Document?.existing_pdf == false){
                    this.loading.invoice = true
                    this.hasInvoice = true
                    this.invoiceId = document.Document.invoice_id
                    if(document.Document.name == 'Tax Invoice') this.isTaxInvoice = true
                    this.loading.merge = true
                }
            })
        }
    }
}
</script>

<style>
    .v-dialog {
        min-width: 300px !important;
        max-width: 400px !important;
    }
</style>